.App {
  background-color: #01010D;
  padding-bottom: 200px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pdf-container {
  width: 80%;
  padding-top: 50px;
  padding-bottom: 150px;
}

.pdf-link {
  color: white;
}

.bibtex-block {
  padding-top: 30px;
  position: relative; /* Ensure the block is positioned relatively */
}

.bibtex-block .copy-button {
  position: absolute; /* Position the button absolutely within the block */
  top: 45px; /* Align to the top */
  right: 50px; /* Align to the right */
  background-color: transparent;
  border: none;
  display: none; /* Hide the button initially */
}

.bibtex-block:hover .copy-button {
  display: block; /* Show the button on hover */
}

/* Override for screens below 768px */
@media (max-width: 768px) {
  .bibtex-block .copy-button {
    display: block; /* Always show the button */
  }
}

.bibtex-block .bibtex {
  border-radius: 6px;
  padding: 50px;
  padding-top: 65px;
  font-size: 0.85em;
  line-height: 1.45;
  white-space: pre-wrap;
  font-family: ui-monospace, SFMono-Regular, "SF Mono", Menlo, Consolas, "Liberation Mono", monospace;
  color: rgb(36, 41, 47);
  background-color: lightgray;
  overflow-y: auto;
}

.small-publication-text {
  font-size: 12px;
  padding-left: 5px;
}

.button-margin {
  margin: 5px;
}

.image-container {
  margin-right: 5vw;
  flex-grow: 1;
  position: relative;
}

.publication-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  display: block; /* Ensure the image is a block element */
}

.publication-image:hover {
  transform: scale(1.1);
}

.publication-content {
  flex-grow: 2;
  font-size: 20px;
  line-height: 30px;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.publication-info-paragraph {
  display: flex;
}

.main-publication-div {
  width: 100%;
  padding-right: 200px; /* Add right padding for better spacing */
  margin-top: 150px; /* Add margin to separate content from header */
  color: white; /* Add text color */
  padding-left: 200px;
}

.publication-flex {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: none;
}

.image-overlay {
  position: absolute; /* Position the overlay absolutely within the container */
  top: 0; /* Align to the top of the container */
  left: 0; /* Align to the left of the container */
  width: 100%; /* Occupy the full width of the container */
  height: 100%; /* Occupy the full height of the container */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Initially invisible */
  transition: opacity 0.3s ease; /* Smooth transition for visibility */
}

/* Text style for the overlay */
.image-overlay-text {
  color: white;
  font-size: 18px;
}

/* Show the overlay and text when hovering over the image */
.image-container:hover .image-overlay {
  opacity: 1; /* Make the overlay visible */
}

@media (max-width: 1200px) {
  .publication-flex {
    flex-direction: column-reverse; /* Changes to a 1-column grid below 700px */
    padding-left: 0px;
    padding-right: 0px;
    gap: 50px;
    justify-content: center;
  }

}

@media (max-width: 768px) {
  .bibtex-block .copy-button {
    display: block;
  }

  .main-publication-div {
    padding-left: 50px;
    padding-right: 50px;
    gap: 50px;
  }

}



