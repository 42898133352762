.navbar {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    padding-right: 200px; /* Add right padding for better spacing */
    padding-left: 200px;
    display: flex;
    align-items: center; 
    position: fixed; /* Make the navbar stick to the top */
    width: 100%; /* Take full width of the screen */
    height: 100px;
    top: 0; /* Stick to the top of the screen */
    left: 0;
    right: 0;
    z-index: 1;
    border-bottom: 1px solid gray;

  }

  .left {
    display: flex;
    align-items: center;
  }
  
  .brand {
    font-weight: bold;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }
  
  .link {
    color: white;
    text-decoration: none;
    margin-left: 20px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }
  
  .link:hover {
    text-decoration: underline;
  }

  .title-link {
    color: white;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }
  
  body {
    padding-top: 100px;
  }

  @media (max-width: 1000px) {
    .navbar {
      padding-right: 25px;
      padding-left: 25px;
    }

    .link {
      font-size: 12px;
    }

    .brand {
      font-size: 12px;
    }
  }