:root {
  --primary-color: #15B7BC;
  --border-radius: 20px;

}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: lightgray;
}

::-webkit-scrollbar-thumb {
  width: 20px;
  background: var(--primary-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0E8286;
}

.montserrat-400 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}


a {
  color: var(--primary-color); /* Change to your desired color */
  text-decoration: none;

}

* {
  box-sizing: border-box;
  margin: 0;
  padding:0;
}

.App {
  background-color: #01010D;
  padding-bottom: 200px;
}


.header-container {
  position: relative;
  height: 600px;
  width: 100%;
  background-image: url('images/home-background-image.jpg');
  background-size: cover;
  background-position: center;
  padding-left: 200px;
  padding-right: 50px;
  display: flex;
  align-items: center; /* Align items vertically */
}

.header-container2 {
  position: relative;
  height: 500px;
  width: 100%;
  background-image: url('images/ai-brain.jpg');
  background-size: cover;
  background-position: center;
  padding-left: 200px;
  padding-right: 50px;
  display: flex;
  align-items: center; /* Align items vertically */
}

.header-container-3 {
  position: relative;
  height: 500px;
  width: 100%;
  background-image: url('images/publication-main-img.jpg');
  background-size: cover;
  background-position: center;
  padding-left: 200px;
  padding-right: 50px;
  display: flex;
  align-items: center; /* Align items vertically */
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, rgba(1, 1, 13, 1)); /* Gradient overlay */
}

.header-text {
  position: relative;
  flex-grow: 1; /* Allow header text to take remaining space */
}

.content {
  padding-left: 20px;
}

.main-title {
  color: white;
  font-size: 55px; /* Increased font size */
  font-weight: bold; /* Made bold */
  padding-right: 20vw;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}

.main-title-2 {
  color: white;
  font-size: 55px; /* Increased font size */
  font-weight: bold; /* Made bold */
  padding-right: 20vw;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}

.paragraph-title {
  margin: 0;
  color: white;
  font-size: 40px; /* Increased font size */
  font-weight: normal; /* Made bold */
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.subtitle {
  margin: 0;
  color: white;
  font-weight: normal; /* Removed bold */
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

.learn-more-button {
  margin-top: 20px; /* Increased margin */
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 20px 40px; /* Increased button size */
  border-radius: 8px; /* Increased border radius */
  cursor: pointer;
  font-size: 18px; /* Increased font size */
  font-weight: 600; /* Made text bold */
  text-decoration: none; /* Remove underline */
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

}

.learn-more-button:hover {
  background-color: #0F9297; /* Darken the color slightly on hover */
}

.header-text > .content > * {
  margin-bottom: 15px;
}

.info-paragraph {
  padding-right: 200px; /* Add right padding for better spacing */
  margin-top: 150px; /* Add margin to separate content from header */
  color: white; /* Add text color */
  padding-left: 200px;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;

}

.info-paragraph p {
  font-size: 20px;
}

.info-paragraph2 {
  margin-top: 50px; /* Add margin to separate content from header */
  color: white; /* Add text color */
  padding-right: 200px;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;

}

.info-paragraph2 p {
  font-size: 20px;
}

.info-paragraph3 {
  margin-top: 20px; /* Add margin to separate content from header */
  color: white; /* Add text color */
  padding-right: 200px;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;

}

.info-paragraph3 p {
  font-size: 20px;
}


.bottom-padding {
  padding-bottom: 600px;
}
.top-padding-small {
  padding-top: 75px;
}

.bold {
  font-weight: bold;
}
.card-container {
  display: flex;
  justify-content: space-between; /* Align cards horizontally with space between */
  gap: 30px;
  margin-top: 40px; /* Add margin to separate from previous content */
}



.card {
  width: 100%; /* Adjust the width of the cards */
  height: 200px;
  border: 1px solid #ccc; /* Gray outline */
  padding: 40px; /* Add padding inside the cards */
  border-radius: var(--border-radius);
  box-sizing: border-box; /* Include padding and border in the total width and height */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease; /* Add transition for smooth hover effect */
}

.card-no-animation {
  width: 100%; /* Adjust the width of the cards */
  height: 200px;
  border: 1px solid #ccc; /* Gray outline */
  padding: 40px; /* Add padding inside the cards */
  border-radius: var(--border-radius);
  box-sizing: border-box; /* Include padding and border in the total width and height */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-content {
  padding-right: 5px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(21, 183, 188, 0.5); /* Add outer glow effect */
}

.card-image-placeholder {
  width: 130px; /* Adjust the width of the image placeholder */
  height: 130px; /* Adjust the height of the image placeholder */
  float: right; /* Float the image placeholder to the left */

}

.large-card-text {
  font-size: 40px;
  font-weight: bold; /* Make large card text bold */
  margin-bottom: 3px; /* Add margin between large and small card text */
  
}

.small-card-text {
  font-size: 16px; /* Set font size for small card text */
}

.blue-line {
  width: 75px; /* Adjust the width of the line */
  height: 5px; /* Adjust the height of the line */
  background-color: var(--primary-color); /* Blue color */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 20px; /* Add some space below the line */
  margin-top: 5px;
  padding-top: 5px;
}

.circle-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Creates a 4-column grid */
  gap: 3.5vw;
  padding-right: 200px; /* Add right padding for better spacing */
  margin-top: 150px; /* Add margin to separate content from header */
  padding-left: 200px;
  width: 100%;

}

.circle-image {
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle-image img {
  max-width: 12vw;
  max-height: 15vw;

}

.circle-image-title {
  font-size: 26px;
  font-weight: bold; /* Make large card text bold */
  /* margin-bottom: 3px; /* Add margin between large and small card text */
  color: white;
  text-decoration: none;
  padding-top: 30px;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}


.column {
  width: 100%; /* Make each column span 25% of the screen width */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-content {
  margin-top: 40px; /* Add margin between circle and cards */
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.card {
  width: 100%; /* Adjust the width of the cards */
  height: 200px; /* Adjust the height of the cards */
  border: 1px solid #ccc; /* Gray outline */
  border-radius: var(--border-radius);
  box-sizing: border-box; /* Include padding and border in the total width and height */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-image-placeholder {
  width: 80px; /* Adjust the width of the image placeholder */
  height: 80px; /* Adjust the height of the image placeholder */
}

.img-container {
  padding-top: 90px;
  display: flex;
  justify-content: center; /* Align cards horizontally with space between */
  margin-top: 20px; /* Add margin to separate from previous content */
}

.img-container img {
  max-height:80%; 
  max-width:80%;
}

.cohens-kappa {
  width: 35%;
  height: 35%
}

.methods-img {
  width: 60%;
  height: 60%;
}

.medium-card-text {
  font-size: 20px;
  font-weight: bold; /* Make large card text bold */
  margin-bottom: 3px; /* Add margin between large and small card text */
  color: white;
  text-decoration: none;
  padding-bottom: 5px;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.tiny-card-text {
  font-size: 14px; /* Set font size for small card text */
  color: white;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.remove-padding-mobile {
  padding-left: 200px;
  padding-right: 200px;
}

/* Modal styles */
.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  background-color: #fefefe;
  position: relative;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.modal-content img {
  max-width: 100%; /* Ensure image doesn't exceed container width */
  max-height: 100%;
  /* Maintain aspect ratio */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
}

.modal.show {
  display: block;
}



@media (max-width: 1600px) {

  .circle-container {
    grid-template-columns: repeat(2, 1fr); /* Changes to a 2-column grid below 1000px */
  }


}

@media (max-width: 1000px) {
  .main-title {
    font-size: 30px /* Set width to 100% at a certain breakpoint */
  }
  .cohens-kappa {
    width: 70%;
    height: 70%
  }

  .methods-img {
    width: 100%;
    height: 100%
  }


}

@media (max-width: 768px) {
  .main-title {
    padding-right: 50px; /* Set width to 100% at a certain breakpoint */
}
.main-title-2 {
  padding-right: 50px;
}
.header-container {
  padding-left: 50px;
}
.header-container2 {
  padding-left: 50px;
}
.header-container-3 {
  padding-left: 50px;
}

.circle-container {
  padding-left: 50px;
  padding-right: 50px;
}

.info-paragraph {
  padding-left: 50px;
  padding-right: 50px;
}

.remove-padding-mobile {
  padding-left: 50px;
  padding-right: 50px;
}
  .circle-container {
    grid-template-columns: 1fr; /* Changes to a 1-column grid below 700px */
  }
  .card-container {
    flex-direction: column;
  }

  .remove-padding-mobile {
    padding-left: 0px;
    padding-right: 0px;
  
  }
}

@media (max-width: 768px) {
  .main-title-2 {
    font-size: 35px /* Set width to 100% at a certain breakpoint */
  }
  .circle-image {
    height: 25vw;
    width: 25vw;
  }

  .circle-image img {
    max-width: 15vw;
  
  }
  .column {
    padding-top: 30px;
  }

  .circle-container {
    margin-top: 40px;
  }

  .info-paragraph {
    margin-top: 40px;
  }
}









