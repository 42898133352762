/* Define new classes for styling the images within the new cards */
.news-card {
    width: calc(32% - 15px);
    height: auto; /* Set height of the card */
    border: 1px solid #ccc; /* Gray outline */
    border-radius: 20px; /* Apply border radius */
    box-sizing: border-box; /* Include padding and border in the total width and height */
    margin-top: 40px; /* Add margin to separate from previous content */
    padding: 20px; /* Add padding inside the cards */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Distribute space between children */
  }

  .main-individual-padding {
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 50px;
  }

  .news-image > img {
    width: 100%; /* Make images fill their containers */
    height: 300px; /* Set height of image placeholder */
    object-fit: cover; /* Ensure images maintain aspect ratio and cover the entire container */
    border-radius: 10px; /* Apply border radius to the image */
  }


  .card-container-news {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 40px; /* Add margin to separate from previous content */
  }

  .news-button {
    width: fit-content;
  }

  .sidebar-div {
    display: flex;
    gap: 10px;
    padding-left: 50px;
    align-items: center;
  }

  .sidebar-text {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
    "wdth" 100;
  }

  @media (max-width: 1000px) {
    .news-card {
        width: 100%; /* Set width to 100% at a certain breakpoint */
    }

    .card-container-news {
        display: block; /* Unset the flex container for card container */
    }


}

@media (max-width: 768px) {
  .info-paragraph3 {
    padding-right: 50px;
  }

  .main-individual-padding {
    padding-left: 50px;
    padding-right: 50px;
  }


}






  